AdvancedFormController.$inject = ['$element', 'allevaApi', /*'darkDeploy',*/ '$scope', '$rootScope', 'allevaAutosave', 'dayjs', 'noty'];
export default function AdvancedFormController($element, allevaApi, /*darkDeploy,*/ $scope, $rootScope, allevaAutosave, dayjs, noty) {


        /***************************
         * Properties
         **************************/
        const ctrl = this;
        
        // Flags
        ctrl.saved        = false;
        ctrl.fromAutosave = false;
        ctrl.initialized  = false;

        // Data
        ctrl.registeredEvents = [];
        ctrl.formDataFacets   = [];
        ctrl.formDataValues   = [];
        ctrl.$element         = $element;

        // Definitions
        const DataKind = { Integer: "Integer", Boolean: "Boolean", Text: "Text", 
            Decimal: "Decimal", Image: "Image", File: "File", DateTime: "DateTime", Signature: "Signature", };

            
        /***************************
         * Init
         **************************/
        async function initializeData() {

            /*****************************************************************
             *  DEVELOPMENT ONLY ENVIRONMENT */
            // We don't want AdvancedForm being initiated if FormId is not defined.


            // if(darkDeploy.staging){
            //     if(typeof ctrl.advancedFormId === 'undefined'){
            //         // TODO
            //         // toastr.warning("Make sure a FormId is being passed to AdancedForm.", "WARNING:");
            //     }
            // }


            /*****************************************************************/
            if (ctrl.advancedFormId) {
                const dataAndFacets = await getAdvancedFormData(ctrl.advancedFormId);

                // TODO upgrade webpack
                ctrl.formDataValues     = dataAndFacets ? dataAndFacets.dataValues || [] : [];
                ctrl.formDataFacets     = dataAndFacets ? dataAndFacets.dataFacets || [] : [];

                ctrl.callEventHandler();
                ctrl.initialized = true;
            }
        }

        ctrl.$onInit = function () {

        };

        ctrl.$postLink = async function () {
            await initializeData();
            ctrl.initializeScopes();
            ctrl.setComponentValues();

            if (ctrl.formObject)
                ctrl.formObject.IsCompleted = isCompleted();

            if (!ctrl.isEditable()) {
                ctrl.setReadOnlyValues();
            }
        }

        ctrl.$doCheck = function () {
            
        }

        /***************************
         * Actions
         **************************/
        ctrl.refresh = async () => {
            await initializeData();
        }

        ctrl.isEditable = () => {
            return ctrl.formMode != 'readonly';
        }

        /***************************
         * Event Handlers
         **************************/
        ctrl.registerEventHandler = (event) => {
            ctrl.registeredEvents.push(event);

            // TODO: Create different event handlers
            // For initialized data
            if(ctrl.initialized){
                ctrl.callEventHandler();
            }
        }

        ctrl.callEventHandler = () => {
            ctrl.registeredEvents.forEach(handler => {
                handler();
            });
        }

        $scope.$on("AdvancedForm.requestSave", (e, markCompleted) => { 
            ctrl.submit(markCompleted);
        });

        $scope.$on("FormDesigner.didSave", () => { 
            // handler code here 
            ctrl.refresh();
        });

        $rootScope.$on("AllevaAutosave.autosave", () => { 
            // Autosave triggered

        /*
            if(ctrl.isDirty){
                ctrl.fromAutosave = true;
                ctrl.submit();
                ctrl.isDirty = false;
            }
            */
        });

        const eventListenerTriggers = () => {
            ctrl.isDirty = true; // Make input dirty.

            // TODO: restore
            //allevaAutosave.trigger(); // map the autosave trigger to this event listener.
        }

        /***************************
         * GET
         **************************/
        ctrl.getComponentValueFromData = function (uuid) {

            const facet = ctrl.formDataFacets.filter(dataFacet => {
                return dataFacet.uuid == uuid;
            });

            if (!facet)
                return null;

            var data = ctrl.formDataValues.filter(dataValue => {
                return dataValue.dataFacetId == facet.id;
            });

            if (!data)
                return null;

            return getValueForKind(data, facet.dataKind);
        };

        ctrl.getComponentValueFromForm = function (uuid) {
            const inputs = getInputs(uuid);
            let   result = "";

            // TODO: stop looping through inputs for every component value
            inputs.forEach(input => {
                
                let type = getType(input);
                switch (type) {
                    case "checkbox": {
                        if (input.checked) {
                            if (result) {
                                result += `,${input.value}`;
                            }
                            else {
                                result += input.value;
                            }
                        }
                        break;
                    }
                    case "radio":
                        if (input.checked) {
                            result = input.value;
                        }
                        break;
                    case "signature":
                        // TODO: get via angular component
                        result = angular.element(input).jSignature('getData');
                        break;
                    case "time":
                        if(input.value)
                            result = dayjs(input.value, 'HH.mm').utc().format('HH:mm');
                        break;
                    case "file":
                        result = angular.element(input).scope().$ctrl.getValue();
                        break;
                    default: 
                        result = input.value;
                        break;
                }
            });

            return result;
        };

        function getType(input) {
            if (input.type)
                return input.type;

            if (input.tagName == "SIGNATURE")
                return "signature";

            if (input.tagName == "SELECT")
                return "dropdown";

            // TODO: handle error condition
        }

        ctrl.getFormComponents = function () {
            return ctrl.formDataFacets;
        }

        function getInputs(uuid) {
            let selector = `[data-guid="${uuid.toLowerCase()}"] input, [data-guid="${uuid.toLowerCase()}"] textarea, [data-guid="${uuid.toLowerCase()}"] select, [data-guid="${uuid.toLowerCase()}"] signature, [data-guid="${uuid.toLowerCase()}"] fileupload`;
            return ctrl.$element[0].querySelectorAll(selector);
        }

        function getReadOnlyElements(uuid) {
            return ctrl.$element[0].querySelectorAll(`[data-guid="${uuid.toLowerCase()}"] #readOnlyValue`);
        }

        ctrl.setComponentValues = function () {
            const dataFacetMap = new Map(ctrl.formDataFacets.map(facet => [facet.id, facet]));
            ctrl.formDataValues.forEach(dataValue => {
                const facet = dataFacetMap.get(dataValue.dataFacetId);

                if (facet) {
                    const inputs = getInputs(facet.uuid);
                    const readOnlyElements = getReadOnlyElements(facet.uuid);
                    const value = getValueForKind(dataValue, facet.dataKind);
                    const defaultReadOnlyElement = readOnlyElements[0];

                    inputs.forEach(input => {

                        // Register an on-chnge event for components
                        input.addEventListener("input", eventListenerTriggers); 

                        let type = getType(input);

                        switch (type) {
                            case "checkbox":
                                if (value) {
                                    const splitValues = value.split(",");
                                    input.checked = splitValues.includes(input.value);
                                }
                                break;
                            case "radio":
                                input.checked = (input.value == value);
                                break;
                            case "time":
                                if(value)
                                    input.value = dayjs.utc(value).local().format('HH:mm');
                                    if (defaultReadOnlyElement) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = dayjs.utc(value).local().format('h:mm a');
                                    }
                                break;
                          case "date":
                                if (value)
                                  input.value = dayjs(value).format("YYYY-MM-DD");
                                  if (defaultReadOnlyElement) {
                                    angular.element(defaultReadOnlyElement)[0].textContent = dayjs(value).format("YYYY-MM-DD");
                                }
                                break;
                            case "signature":
                                // TODO: set via angular component
                                setTimeout(() => {
                                    angular.element(input.querySelector('.signature-canvas-target')).jSignature('setData', value);
                                    angular.element(defaultReadOnlyElement)[0].src = value;
                                }, 500)
                                break;
                            case "file":
                                angular.element(input).scope().$ctrl.setValue(value);
                                break;
                            case "select-one":
                                input.value = value;
                                input.forEach(option => {
                                    if (option.selected) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = option.label;
                                    }
                                });
                                break;
                            case "textarea":
                                input.value = value;    
                                angular.element(defaultReadOnlyElement)[0].outerHTML = "<p id=readOnlyValue hidden=\"\">" + value.replace(/\n/g, "<br>") + "</p>";
                                break;
                            default:
                                input.value = value;
                                if (defaultReadOnlyElement) {
                                    angular.element(defaultReadOnlyElement)[0].textContent = value;
                                }
                                break;
                        }
                    });                    
                }
            });
        }

        ctrl.initializeScopes = function () {
            // hacky
            ctrl.formDataFacets.forEach(facet => {
                const inputs = getInputs(facet.uuid);
                inputs.forEach(input => {
                    let type = getType(input);
                    switch (type) {
                        case "file":
                            angular.element(input).scope().$ctrl.leadId = this.leadId;
                            break;
                    }
                });
            });
        }

        ctrl.setReadOnlyValues = function () {
            ctrl.formDataFacets.forEach(facet => {
                const inputs = getInputs(facet.uuid);
                var readOnlyElements = getReadOnlyElements(facet.uuid);
                var defaultReadOnlyElement = readOnlyElements[0];
                var labelElements = ctrl.$element[0].querySelectorAll(`[data-guid="${facet.uuid.toLowerCase()}"] label`)
                labelElements.forEach(label => {
                    label.style.fontWeight = 'bold';
                });
                if (facet.dataKind == DataKind.Signature) {
                    inputs.forEach(input => {
                        setTimeout(() => {
                            if (input.type != "button") {
                                var signatureCanvas = angular.element(input.querySelector('.signature-canvas-target'));
                                signatureCanvas[0].hidden = true;
                                var clearButton = input.querySelector('#clearFormSignatureButton')
                                clearButton.parentNode.removeChild(clearButton);
                                defaultReadOnlyElement.hidden = false;
                            }
                        }, 500)
                    });
                }
                // TODO: Hacky, need to fix after mvp release
                else {
                    inputs.forEach(input => {
                        input.style.display = 'none';
                        input.hidden = true;

                        if (input.tagName == "FILEUPLOAD") {
                            var uploadFileComponent = ctrl.$element[0].querySelector(`[data-guid="${facet.uuid.toLowerCase()}"]`);
                            uploadFileComponent.style.display = 'none'
                            uploadFileComponent.hidden = true;
                        }                        
                    });
                    if (defaultReadOnlyElement) {
                        readOnlyElements.forEach(element => {
                            element.hidden = false;
                            element.style.visibility = '';
                        })

                        
                    }
                    
                }                          
            });
        }

        // TODO: This is a temporary fix for saving read only html.  Will need to change.
        const getReadOnlyHtml = (advancedFormId) => {
            // Create a copy of the form
            var tempElement = document.createElement('div');
            tempElement.innerHTML = angular.element(document.querySelector('#advancedForm_' + advancedFormId))[0].innerHTML;

            // Get the inputs and elements we want to hide for read only
            var formInputs = tempElement.getElementsByTagName('input');
            var formTextArea = tempElement.getElementsByTagName('textarea');
            var formSelect = tempElement.getElementsByTagName('select');
            var formSignature = tempElement.getElementsByTagName('signature');
            var readOnlyElements = tempElement.querySelectorAll('#readOnlyValue');
            var submitButtons = tempElement.getElementsByTagName('button');
            var fileUpload = tempElement.getElementsByClassName('file-upload-component');
            var labelElements = tempElement.getElementsByTagName(`label`);
            var transcludeElements = tempElement.getElementsByClassName('advanced-form-content');

            while (formInputs.length) {
                formInputs[0].remove();
            }
            while (formTextArea.length) {
                formTextArea[0].remove();
            }
            while (formSelect.length) {
                formSelect[0].remove();
            }
            readOnlyElements.forEach((element) => {
                element.hidden = false;
                element.style.visibility = '';
            });
            while (submitButtons.length) {
                submitButtons[0].remove();
            }
            while (formSignature.length) {
                formSignature[0].remove();
            }
            while (fileUpload.length) {
                fileUpload[0].remove();
            }
            labelElements.forEach(label => {
                var labelElement = angular.element(label)[0];
                labelElement.outerHTML = "<label><b>" + labelElement.textContent + "</b></label>";
            })
            transcludeElements.forEach(element => {
                element.removeAttribute("ng-transclude");
            })

            // return read only html
            var formHtml = angular.element(tempElement)[0].innerHTML;
            return formHtml;
        }

        function getAdvancedFormDataFacets(formId) {

            return allevaApi.AdvancedForms.AdvancedFormElements.getElementsForFormId(formId)
                .then(advancedFormElements => {
                    if (!advancedFormElements){ 
                        // this form has no links to DataFacets
                        return;
                    }

                    const dataFacetIds = advancedFormElements.map(element => element.dataFacetId);
                    return allevaApi.AdvancedForms.DataFacets.search(dataFacetIds)
                    .then(dataFacets => {
                        return dataFacets;
                    })
                });
        }

        function getAdvancedFormData(formId = null) {

            const id = (formId !== null && formId !== undefined) ? formId : ctrl.advancedFormId;
            return getAdvancedFormDataFacets(id)
                .then((dataFacets) => {

                    if (!dataFacets)
                        return;

                    const data = {
                        leadId      : ctrl.leadId,
                        datafacetIds: dataFacets.map(facet => facet.id)
                    };

                   return allevaApi.AdvancedForms.DataValues.search(data)
                        .then((dataValues) => {
                            return {
                                dataValues: dataValues,
                                dataFacets: dataFacets
                            };
                        });
                });
        }

        /***************************
         * Saves
         **************************/
        ctrl.submit = function (markCompleted) {
            // TODO: validation
            let promise = saveData()
            .then((result) => {
                ctrl.saved = true;

                var advancedFormData = {
                    AdvancedFormId: ctrl.advancedFormId,
                    LeadId: ctrl.leadId,
                    AdvancedFormHtml: getReadOnlyHtml(ctrl.advancedFormId)
                }
                allevaApi.AdvancedForms.saveAdvancedFormHtnl(advancedFormData);

                if(ctrl.fromAutosave){
                    let alert = "<h5>Dashboard</h5><p>Form autosaved.</p>";
                    new noty({
                        text: alert,
                        timeout: 2000,
                        type: 'success'
                    }).show();

                    ctrl.fromAutosave = false;
                }
                else{
                    let alert = "<h5>Form data saved successfully.</p>";
                    new noty({
                        text: alert,
                        timeout: 2000,
                        type: 'success'
                    }).show();

                    ctrl.formObject.IsCompleted = isCompleted();
                }

                $scope.$emit("AdvancedForm.saveComplete", ctrl.saved);
            })
            .catch((error) => {
                let alert = "<h5>Error saving form data.</p>";
                    new noty({
                        text: alert,
                        timeout: 8000,
                        type: 'error'
                    }).show();

                ctrl.saved = false;
                $scope.$emit("AdvancedForm.saveComplete", ctrl.saved, error);
              });
        }

        const saveData = () => {

            const data = ctrl.formDataFacets.map(dataFacet => {

                const value = ctrl.getComponentValueFromForm(dataFacet.uuid);

                // TODO: handle null dataValue
                const item  = {
                    Id            : value.id,
                    DataFacetId   : dataFacet.id,
                    IntegerValue  : dataFacet.dataKind == DataKind.Integer ? value  : null,
                    StringValue   : dataFacet.dataKind == DataKind.Text ? value     : null,
                    DateTimeValue : dataFacet.dataKind == DataKind.DateTime ? value : null,
                    DecimalValue  : dataFacet.dataKind == DataKind.Decimal ? value  : null,
                    ImageValue    : dataFacet.dataKind == DataKind.Image ? value    : null,
                    FileValue     : dataFacet.dataKind == DataKind.File ? value     : null,
                    SignatureValue: dataFacet.dataKind == DataKind.Signature ? value: null,
                    BooleanValue  : dataFacet.dataKind == DataKind.Boolean ? value  : null,
                    leadId        : ctrl.leadId
                };

                const inputs = getInputs(dataFacet.uuid);
                var readOnlyElements = getReadOnlyElements(dataFacet.uuid);
                var defaultReadOnlyElement = readOnlyElements[0];
                inputs.forEach(input => {
                    switch (input.type) {
                        case 'select-one':
                            if (defaultReadOnlyElement) {
                                input.forEach(option => {
                                    if (option.selected) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = option.label;
                                    }
                                });
                            }
                            break;
                        case 'time':
                            angular.element(defaultReadOnlyElement)[0].textContent = dayjs.utc(value, 'h.mm.a').local().format('h:mm a');
                            break;
                        case 'checkbox':
                            if (value) {
                                const splitValues = value.split(",");
                                angular.element(input).attr('checked', splitValues.includes(input.value));
                            }
                            readOnlyElements[input.id].src = input.checked ? 'Images/faChecked.png' : 'Images/faCross.png';
                            break;
                        case 'radio':
                            angular.element(input).attr('checked', (input.value == value));
                            readOnlyElements[input.id].src = input.checked ? 'Images/faChecked.png' : 'Images/faCross.png';
                            break;
                        case 'textarea':
                            angular.element(defaultReadOnlyElement)[0].outerHTML = "<p id=readOnlyValue hidden=\"\">" + value.replace(/\n/g, "<br>") + "</p>";
                            break;
                        default:
                            if (defaultReadOnlyElement && input.tagName == 'SIGNATURE') {
                                angular.element(input.querySelector('.signature-canvas-target')).jSignature('setData', value);
                                angular.element(defaultReadOnlyElement).attr('src', value);
                            }
                            else if (defaultReadOnlyElement) {
                                angular.element(defaultReadOnlyElement)[0].textContent = value;
                            }
                            break;
                    }
                });
                return item;
            });

            return allevaApi.AdvancedForms.submitForm(ctrl.advancedFormId, ctrl.leadId, data);
        }
        
        /***************************
         * Helpers
         **************************/
        const getValueForKind = (data, kind) => {
            switch (kind) {
                case DataKind.Integer: 
                    return data.integerValue;
                case DataKind.Boolean: 
                    return data.booleanValue;
                case DataKind.Text: 
                    return data.stringValue;
                case DataKind.Decimal: 
                    return data.decimalValue;
                case DataKind.Image: 
                    return data.imageValue;
                case DataKind.File: 
                    return data.fileValue;
                case DataKind.DateTime: 
                    return data.dateTimeValue;
                case DataKind.Signature: 
                    return data.signatureValue;
            }
        }

        function isCompleted() {
            // TODO: iterate over required values
            return true;
        }

    };

